import { Flex, HStack, Image, useBreakpointValue } from 'native-base';
import { HeaderActions } from './HeaderActions';
import { CoBrandingLogo } from './CoBrandingLogo';

const logo = '../../FA__ayble_health_Logo_Isabelline_white.png';

type Props = {
  onGetStartedButtonClick: () => void;
};

export const Header = ({ onGetStartedButtonClick }: Props) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  return (
    <Flex
      alignItems={isMobile ? 'center' : undefined}
      bgColor={'secondary.500'}
      direction={isMobile ? 'column' : 'row'}
      height={isMobile ? undefined : 128}
      justifyContent={'space-between'}
      px={6}
    >
      <HStack>
        <Image
          alt="Ayble Health logo"
          resizeMode={'contain'}
          size={'xl'}
          source={{
            uri: logo,
          }}
          testID={'ayble-health-logo'}
        />

        <CoBrandingLogo />
      </HStack>

      <HeaderActions onGetStartedButtonClick={onGetStartedButtonClick} />
    </Flex>
  );
};
