import styled from '@emotion/styled';
import {
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import { AffiliateType } from '../../models/affiliate';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import {
  HEADER_1_MOBILE_SIZE_PX,
  HEADER_1_SIZE_PX,
  MAX_CONTENT_WIDTH_PX,
} from './constants';

const redArrowUpImg = '../../red_arrow_up.png';
const celebrateImg = '../../celebrate-phase1-completion.png';

const ImgContainer = styled(VStack)`
  & > div > div {
    background-position: left;
  }
`;

// map of key-value pairs where the key is an affiliate type,
// and the value is a function returning the header and the subheader message elements.
type AffiliateTypeInfoFnMap = Record<
  AffiliateType,
  (affiliateName: string | undefined) => {
    header: React.ReactNode;
    subheader: React.ReactNode;
  }
>;

const messagesAffiliateTypeMap: AffiliateTypeInfoFnMap = {
  [AffiliateType.employer]: (affiliateName: string | undefined) => ({
    header: <>Digestive care at $0 cost to you</>,
    subheader: (
      <>
        Personalized GI nutrition and mental health care — free for{' '}
        {affiliateName} employees
      </>
    ),
  }),
  [AffiliateType.healthPlan]: () => ({
    header: <>Expert Digestive Care Starts Here</>,
    subheader: (
      <>Personalized GI nutrition and mental health care for as low as $0</>
    ),
  }),
  [AffiliateType.healthSystem]: (affiliateName: string | undefined) => ({
    header: <>Expert Digestive Care Starts Here</>,
    subheader: (
      <>
        {affiliateName} & Ayble team up offering GI nutrition and mental health
        support
      </>
    ),
  }),
};

type Props = {
  onGetStartedButtonClick: () => void;
};

export const Headline = ({ onGetStartedButtonClick }: Props) => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  const genFn = fetchedAffiliate?.affiliate_type
    ? messagesAffiliateTypeMap[fetchedAffiliate.affiliate_type]
    : messagesAffiliateTypeMap[AffiliateType.healthPlan];
  const defaultElements = genFn(fetchedAffiliate?.name);

  const headerMessage = fetchedAffiliate?.header || defaultElements.header;
  const subheaderMessage =
    fetchedAffiliate?.subheader || defaultElements.subheader;

  return (
    <Center backgroundColor={'secondary.500'}>
      <VStack
        alignItems={'center'}
        position={'relative'}
        maxW={MAX_CONTENT_WIDTH_PX}
        width={'100%'}
        p={{ base: 8, lg: 24 }}
        pt={{ base: 8, sm: 0 }}
      >
        <HStack
          width={'100%'}
          bgColor={'muted.100'}
          p={[6, 16]}
          rounded={['32px', '64px']}
        >
          <VStack flex={2}>
            <Heading
              color={'black'}
              fontWeight={'medium'}
              letterSpacing={'0px'}
              fontSize={{ base: HEADER_1_MOBILE_SIZE_PX, sm: HEADER_1_SIZE_PX }}
              textAlign="left"
            >
              {headerMessage}
            </Heading>

            <VStack maxW={'700px'}>
              <Text
                color={'black'}
                fontSize={['xl', '2xl', '3xl']}
                fontWeight={'medium'}
                pt={[4, 6]}
              >
                {subheaderMessage}
              </Text>
            </VStack>

            {isMobile && (
              <ImgContainer
                flex={1}
                alignItems={'center'}
                justifyContent={'center'}
                position={'relative'}
              >
                <Image
                  alt="Celebrate Image"
                  height={'180px'}
                  mb={-6}
                  mt={3}
                  resizeMode={'contain'}
                  source={{
                    uri: celebrateImg,
                  }}
                  width={'150px'}
                />
              </ImgContainer>
            )}

            <Button
              _text={{ color: 'secondary.900', textTransform: 'none' }}
              height={{ base: undefined, sm: '72px' }}
              minW={{ base: undefined, md: '300px' }}
              mt={10}
              onPress={onGetStartedButtonClick}
              rounded={'72px'}
              size={{ base: 'sm', sm: 'md' }}
              w={['100%', '70%', '40%']}
            >
              <Text
                color={'secondary.500'}
                fontSize={{ base: undefined, sm: 'xl' }}
                fontWeight={'bold'}
              >
                GET STARTED
              </Text>
            </Button>

            {!isMobile && (
              <HStack alignItems={'flex-end'} flex={1} ml={-12} mt={8}>
                <Image
                  resizeMode={'contain'}
                  size={'lg'}
                  source={{
                    uri: redArrowUpImg,
                  }}
                  alt="Red Arrow Up"
                />

                <Text
                  color={'black'}
                  fontSize={'md'}
                  fontWeight={'normal'}
                  flexWrap={'wrap'}
                >
                  <u>92%</u> of Ayble Health users significantly improve their
                  GI symptoms*
                  <br />
                  Results you can feel in as little as <u>7</u> days!
                </Text>
              </HStack>
            )}
          </VStack>

          {!isMobile && (
            <ImgContainer
              bottom={['8px', '72px']}
              flex={1}
              justifyContent={'end'}
              position={{ base: 'absolute', md: undefined }}
              right={0}
            >
              <Image
                alt="Celebrate Image"
                height={{ base: '180px', md: '300px', lg: '400px' }}
                mb={{ base: 24, md: 0, lg: -16 }}
                ml={{ base: 4, lg: undefined }}
                resizeMode={'contain'}
                source={{
                  uri: celebrateImg,
                }}
                width={{ base: '150px', md: undefined, lg: undefined }}
              />
            </ImgContainer>
          )}
        </HStack>
      </VStack>
    </Center>
  );
};
