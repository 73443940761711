import { VStack } from 'native-base';
import { Banner } from './Banner';
import { Body } from './Body';
import { Header } from './Header';
import { FORM_ACTIONS } from '../../utils/formActions';
import { useEffect } from 'react';
import { useAffiliate } from '../../api/affiliates/useAffiliate';
import { useFormData } from '../../utils/formReducer';

type Props = {
  onStartOnboarding: () => void;
};

export const LandingPage = ({ onStartOnboarding }: Props) => {
  const { dispatch } = useFormData();
  const { affiliate } = useAffiliate();

  useEffect(() => {
    if (!!affiliate) {
      dispatch({
        type: FORM_ACTIONS.INIT_AFFILIATE,
        payload: affiliate,
      });
    }
  }, [affiliate, dispatch]);

  return (
    <VStack testID={'landing-page'}>
      <Banner />

      <Header onGetStartedButtonClick={onStartOnboarding} />

      <Body onGetStartedButtonClick={onStartOnboarding} />
    </VStack>
  );
};
