import styled from '@emotion/styled';
import { Divider, HStack, Image } from 'native-base';
import { useAffiliate } from '../../api/affiliates/useAffiliate';

const ImgContainer = styled('span')`
  & > div > div {
    background-position: left;
  }
`;

export const CoBrandingLogo = () => {
  const { affiliate } = useAffiliate();
  const { logo: affiliateLogo, utm_source: affiliateUtmSource } =
    affiliate || {};

  if (!affiliateLogo) return null;

  return (
    <HStack alignItems={'center'}>
      <Divider
        bgColor={'white'}
        height={'50%'}
        orientation={'vertical'}
        mx={8}
        testID={'cobranding-divider'}
        thickness={1}
      />

      <ImgContainer>
        <Image
          alt={`${affiliateUtmSource} logo`}
          maxHeight={'90px'}
          resizeMode={'contain'}
          size={'xl'}
          source={{
            uri: affiliateLogo,
          }}
          testID={'cobranding-logo'}
        />
      </ImgContainer>
    </HStack>
  );
};
