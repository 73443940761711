import { useEffect, useState } from 'react';
import {
  Text,
  Heading,
  VStack,
  Button,
  HStack,
  Box,
  Checkbox,
  Pressable,
  Spinner,
} from 'native-base';

import useStripePlans, {
  ProductCategory,
} from '../../api/onboarding/useStripePlans';
import { StripePrice } from '../../types';

const styles = {
  selectedButton: {
    backgroundColor: 'warmGray.300',
  },
  planBox: {
    py: 4,
    px: 4,
    borderRadius: 12,
    my: 6,
    w: '100%',
  },
  planBackground: {
    0: 'blue.100',
    1: 'amber.300',
  },
  badge: {
    top: -16,
    left: 6,
    py: 1,
    px: 4,
    borderRadius: 16,
    mr: 'auto',
    background: 'secondary.500',
  },
};

const ProductSelector = ({
  onConsultationClick,
  onPlanSelect,
  affiliate,
  lookedUpAffiliate,
  skippedEligibilityCheck,
}: {
  onConsultationClick: () => void;
  onPlanSelect: (price: StripePrice | null) => void;
  affiliate;
  lookedUpAffiliate: boolean;
  skippedEligibilityCheck: boolean;
}) => {
  const { planConfig, error, loading } = useStripePlans();
  // selectedPrice is a priceId
  const [selectedPrice, setSelectedPrice] = useState<string | null>(null);
  const [productCategory, setProductCategory] = useState<
    ProductCategory | undefined
  >();
  const selectedPlanConfig = planConfig?.[productCategory!];

  useEffect(() => {
    // set category once data has been fetched - defaults to 'digital' if both are present
    if (!loading && !error && !productCategory) {
      setProductCategory(Object.keys(planConfig)[0] as ProductCategory);
    }
  }, [error, loading, planConfig, productCategory]);

  useEffect(() => {
    if (!selectedPlanConfig) return;

    const { prices, product: { default_price } = {} } = selectedPlanConfig;
    // find default price
    const defaultProductPrice = prices?.find(
      (price) => price.id === default_price?.id
    );
    // when category changes, set selected price to null or default price
    setSelectedPrice(defaultProductPrice ? defaultProductPrice.id : null);
  }, [selectedPlanConfig]);

  const formatPriceStr = (priceStr: string, symbol = '$') => {
    return `${symbol}${priceStr.slice(0, -2)}.${priceStr.slice(-2)}`;
  };

  const getPriceSubHeading = (priceStr: string, interval?: number) => {
    if (interval === 1) {
      return 'Monthly';
    }

    if (interval! > 1) {
      return `Billed at ${formatPriceStr(
        priceStr
      )} USD every ${interval} months`;
    }
  };

  const handleSubmit = () => {
    const plan = selectedPlanConfig?.prices?.find(
      (price) => price.id === selectedPrice
    );
    if (plan) onPlanSelect(plan);
  };

  if (loading) return <Spinner />;

  const showNotCovered =
    !skippedEligibilityCheck &&
    affiliate &&
    !affiliate.covers_ayble &&
    lookedUpAffiliate;

  return (
    <VStack mb={4} maxW={520} mx={'auto'}>
      <Heading fontSize={'3xl'} mb={'2'}>
        Start your 7-day free trial with Ayble
      </Heading>
      {showNotCovered && (
        <Text textAlign={'center'} mt={5} fontSize={'md'} color={'red.500'}>
          Sorry, your employer or insurance does not cover Ayble at this time.
          Sign up for one of our low-price plans!
        </Text>
      )}
      <Text textAlign={'center'} mt={8} fontSize={'lg'}>
        Select a plan:
      </Text>
      <HStack
        h={8}
        mx={'auto'}
        my={4}
        p={0.5}
        borderWidth={1}
        borderRadius={4}
        borderColor={'warmGray.300'}
        backgroundColor={'warmGray.200'}
      >
        {(Object.keys(planConfig) as Array<ProductCategory>).map(
          (category: ProductCategory) => (
            <Button
              key={category}
              fontFamily={'body'}
              size={'md'}
              minW={'unset'}
              minH={'unset'}
              mx={'auto'}
              w={'50%'}
              borderRadius={4}
              onPress={() => setProductCategory(category)}
              backgroundColor={
                productCategory !== category ? 'warmGray.200' : undefined
              }
            >
              <Text fontWeight={'bold'} color={'secondary.500'}>
                {planConfig[category]?.toggleButtonText}
              </Text>
            </Button>
          )
        )}
      </HStack>
      {selectedPlanConfig && (
        <VStack alignItems={'flex-start'} maxW={400} mx={'auto'}>
          {selectedPlanConfig.product?.metadata.display_name && (
            <Heading
              mt={4}
              mb={2}
              fontFamily={'body'}
              fontWeight={'bold'}
              fontSize={'22px'}
            >
              {selectedPlanConfig.product.metadata.display_name}
            </Heading>
          )}
          <Text fontSize={'16px'}>
            {selectedPlanConfig?.product?.description}
          </Text>
          {selectedPlanConfig?.prices?.map((priceOption, i) => {
            const {
              id: priceId,
              unit_amount,
              unit_amount_decimal,
              recurring,
              metadata: { badge },
            } = priceOption;
            const { interval_count } = recurring || {};
            const subHeading = getPriceSubHeading(
              unit_amount_decimal,
              interval_count
            );

            return (
              <Pressable
                {...styles.planBox}
                backgroundColor={styles.planBackground[i]}
                onPress={() => setSelectedPrice(priceId)}
                key={priceId}
              >
                {!!badge && (
                  <Box position={'absolute'} {...styles.badge}>
                    <Text fontWeight={'bold'} color="white">
                      {badge}
                    </Text>
                  </Box>
                )}
                {subHeading && (
                  <Text
                    fontWeight={'regular'}
                    position={'absolute'}
                    top={4}
                    left={4}
                    right={0}
                  >
                    {subHeading}
                  </Text>
                )}
                <HStack>
                  <VStack flexGrow={1}>
                    <Heading
                      size={'xl'}
                      fontFamily={'body'}
                      fontWeight={'extrabold'}
                      textAlign={'left'}
                      mt={4}
                    >
                      {'7 days free'}
                    </Heading>
                    <Text fontWeight={'regular'} mt={2}>
                      {`${formatPriceStr(
                        (unit_amount / (interval_count || 1)).toString()
                      )} USD/month`}
                    </Text>
                  </VStack>
                  <Checkbox
                    accessibilityLabel="select plan"
                    size={'lg'}
                    my={'auto'}
                    ml={2}
                    justifyContent={'flex-end'}
                    isChecked={priceId === selectedPrice}
                    value={i.toString()}
                    borderWidth={1}
                    borderRadius={1}
                    borderColor={'warmGray.900'}
                  />
                </HStack>
              </Pressable>
            );
          })}
          <Button
            my={2}
            isDisabled={!selectedPrice}
            mx={'auto'}
            onPress={handleSubmit}
          >
            <Text color="white" fontWeight={'bold'}>
              Try for Free
            </Text>
          </Button>
          <Text textAlign={'center'} my={6} mx={'auto'}>
            Cancel any time, no commitment
          </Text>
        </VStack>
      )}
    </VStack>
  );
};

export default ProductSelector;
