import styled from '@emotion/styled';
import {
  Heading,
  ITheme,
  Text,
  Box,
  Center,
  useBreakpointValue,
} from 'native-base';
import { Headline } from './Headline';
import { Drophead } from './Drophead';
import { Logos } from './Logos';
import { Info } from './Info';
import { Claim } from './Claim';
import { Footer } from './Footer';
import { Guidance } from './Guidance';
import {
  BODY_2_MOBILE_SIZE_PX,
  BODY_2_SIZE_PX,
  HEADER_4_MOBILE_SIZE_PX,
  HEADER_4_SIZE_PX,
} from './constants';
import { ScrollingText } from './ScrollingText';

const Link = styled('a')<{ theme?: ITheme }>`
  color: ${(p) => p.theme.colors.tertiary['500']};
  transition: color linear 200ms;

  &:hover {
    color: ${(p) => p.theme.colors.red['500']};
  }
`;

type Props = {
  onGetStartedButtonClick: () => void;
};

export const Body = ({ onGetStartedButtonClick }: Props) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  return (
    <>
      <Headline onGetStartedButtonClick={onGetStartedButtonClick} />

      {isMobile && <ScrollingText />}

      <Guidance onStartOnboarding={onGetStartedButtonClick} />

      <Center backgroundColor={'secondary.500'} px={8} py={16}>
        <Heading
          color={'white'}
          fontSize={{ base: HEADER_4_MOBILE_SIZE_PX, sm: HEADER_4_SIZE_PX }}
          pb={6}
        >
          Curious if Ayble is right for you?{' '}
        </Heading>

        <Box pb={12} textAlign={'center'}>
          <Text
            fontSize={{ base: BODY_2_MOBILE_SIZE_PX, sm: BODY_2_SIZE_PX }}
            color={'white'}
            mb={1}
          >
            Email us at{' '}
            <Link href="mailto:support@ayblehealth.com">
              support@ayblehealth.com
            </Link>
          </Text>

          <Text
            fontSize={{ base: BODY_2_MOBILE_SIZE_PX, sm: BODY_2_SIZE_PX }}
            color={'white'}
          >
            Or call us at <Link href="tel:8574169299">857-416-9299</Link>
          </Text>
        </Box>
      </Center>

      <Drophead onGetStartedButtonClick={onGetStartedButtonClick} />
      <Logos />
      <Info />
      <Claim onButtonClick={onGetStartedButtonClick} />
      <Footer />
    </>
  );
};
