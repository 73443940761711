import { useCallback } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

//Array for doing lookup by page number from main Form
const trackingViewNames = [
  '',
  'Program Goals',
  'Init Value prop',
  'Sex',
  'Ethinicity',
  'Pregnancy',
  'Eating Disorder',
  '',
  'Second Value prop',
  'Symptoms',
  'Diagnosis',
];

export const useAnalyticsHelper = () => {
  const sendDataToGTM = useGTMDispatch();

  const updateTrackingLayer = useCallback(
    (pageNum, addParam) => {
      // test if input is string or Num
      let lookup;
      if (typeof pageNum === 'string') {
        lookup = pageNum;
      } else {
        lookup = trackingViewNames[pageNum];
      }
      let payload = `${lookup}${addParam ? ` - ${addParam}` : ''}`;

      // send page view event to Google Tag Manager
      // because not supported in Segment
      sendDataToGTM({ event: 'page_view', page_title: payload });
    },
    [sendDataToGTM]
  );

  return { updateTrackingLayer };
};
