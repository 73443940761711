import { Image, VStack, Flex, Text, FlatList, Heading } from 'native-base';

const symptomImprovements = [
  {
    text: 'Build a 100% personalized gut health plan and find your trigger foods.',
    image: './../stomach.png',
  },
  {
    text: 'Mind-Gut program to help calm your mind and gut.',
    image: './../mind-gut-i.png',
  },
  {
    text: 'Access your very own expert digestive care team anytime, from anywhere (PRO plans only).',
    image: './../chat-bubble.png',
  },
  {
    text: 'Track your gut health insights and identify the root causes of flares and discomfort.',
    image: './../phases.png',
  },
  {
    text: 'Use our food discovery engine and item scanner to remove the guesswork at the grocery store.',
    image: './../groceries.png',
  },
  {
    text: 'Learn from GI experts and people just like you.',
    image: './../book.png',
  },
];

const logoStyles = {
  backgroundSize: 'contain',
  maxHeight: '36px',
  resizeMode: 'contain',
  position: 'relative',
};

const ValueProp = () => (
  <VStack space={1} className="header">
    <Heading mt={4} fontSize={'2xl'}>
      The Ayble Experience
    </Heading>
    <FlatList
      data={symptomImprovements}
      renderItem={({ item }) => (
        <Flex
          key={item.text}
          direction="row"
          alignItems={'center'}
          mb={2}
          py={6}
          px={4}
        >
          <Image
            size={'md'}
            style={logoStyles}
            source={{
              uri: item.image,
            }}
            alt="logo image image"
          />
          <Text fontSize={18} fontWeight={'normal'} pl={'10px'}>
            {item.text}
          </Text>
        </Flex>
      )}
    />
  </VStack>
);

export default ValueProp;
