import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, VStack, Center, Divider } from 'native-base';

import HeaderNav from '../HeaderNav/HeaderNav';
import { useAnalyticsHelper } from '../../hooks/useAnalyticsHelper';
import { useSegment } from '../../hooks/useSegment';
import PlansDisclaimer from './PlansDisclaimer';
import PlansValueProp from './PlansValueProp';
import { useApi, useAuth } from '../../hooks';
import ProductSelector from './ProductSelector';
import {
  FREE_CALL_CALENDAR_LINK,
  LOCAL_STORAGE_USER_INFO_KEY,
} from '../../constants/misc';
import {
  TRACKING_FREE_CONSULTATION_LINK_CLICK,
  TRACKING_MEMBERSHIP_PAGE,
  TRACKING_STRIPE_PAYMENT_PAGE,
} from '../OnboardingForm/constants';

const { REACT_APP_WEB_BASE_URL = 'http://localhost:3000' } = process.env;

const areStripeUserInfoValid = (userId, userEmail) => {
  if (!userId || !userEmail) return false;

  const localStorageUserInfo = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_USER_INFO_KEY) || '{}'
  );
  const {
    userEmail: localStorageUserEmail = null,
    userId: localStorageUserId = null,
  } = localStorageUserInfo;

  return userId === localStorageUserId && userEmail === localStorageUserEmail;
};

const computeUserInformation = (
  locationState,
  authenticatedUser,
  searchParams
) => {
  const userId = locationState?.userId || authenticatedUser?.id;
  const userEmail = locationState?.userEmail || authenticatedUser?.email;

  if (userId && userEmail) return { userId, userEmail, isFromStripe: false };

  const userIdQueryParam = decodeURIComponent(searchParams.get('userId'));
  const userEmailQueryParam = decodeURIComponent(searchParams.get('userEmail'));

  return {
    userId: userIdQueryParam,
    userEmail: userEmailQueryParam,
    isFromStripe: userIdQueryParam && userEmailQueryParam,
  };
};

function Plans() {
  const { api } = useApi();
  const { user: authenticatedUser, loading } = useAuth();
  const { search, state } = useLocation();

  const searchParams = new URLSearchParams(search);

  // There are 4 main ways to access this page:
  // 1. new users not authenticated - will be redirected to the home page
  // 2. new users that just finished the onboarding - will be considered onboarded but not registered yet
  //    until they finish the Stripe flow successfully. We take information about these users (id and email)
  //    needed to complete the Stripe flow from the location state since it is set when handling user
  //    registration inside "handleUserRegister" in Form.js. For such users we also track the visit of this page.
  // 3. users that previously completed the onboarding but didn't finish the Stripe flow - they land
  //    in this page once logged in to finish the registration. For these users we pick the information
  //    we need to complete the Stripe flow from the "authenticatedUser" object since they had to login
  //    in order to complete the registration. We do not track the page visit in this case since it
  //    has been already tracked during the first time they landed here.
  // 4. users coming from Stripe - when proceeding to the Stripe flow, in the Stripe payment page there
  //    is the possibility of going back to the page specified in the "cancel_url" parameter set inside
  //    "handlePlanSelect" in this file. In this case, the information needed the complete the Stripe
  //    flow in case they will move forward again are taken from the local storage (info have been saved
  //    there the first time users land on this page from the onboarding). For security reasons, we
  //    perform a check between the info we get back from Stripe and the info we have in local storage,
  //    if they do not match possibly users are trying to overtake this page somehow.

  const { userId, userEmail, isFromStripe } = computeUserInformation(
    state,
    authenticatedUser,
    searchParams
  );

  const { updateTrackingLayer } = useAnalyticsHelper();
  const { page: pageView, track } = useSegment();
  const navigate = useNavigate();
  const comesFromOnboarding = !!state?.userId;
  const noUser = !state?.userId && !authenticatedUser?.id;

  useEffect(() => {
    if (!comesFromOnboarding) return;

    localStorage.setItem(
      LOCAL_STORAGE_USER_INFO_KEY,
      JSON.stringify({
        userEmail,
        userId,
      })
    );
    pageView(TRACKING_MEMBERSHIP_PAGE);
    updateTrackingLayer(`Select plan`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectHome = () => {
    navigate(`/${search}`, {
      state: { toRegister: true },
    });
  };

  const handleConsultationClick = () => {
    track(TRACKING_FREE_CONSULTATION_LINK_CLICK, {
      user_id: userId,
    });
    updateTrackingLayer(TRACKING_MEMBERSHIP_PAGE);
    window.open(FREE_CALL_CALENDAR_LINK);
  };

  const handleStripeCheckout = async (checkoutData) => {
    try {
      const checkoutRequest = {
        route: '/private/stripe/checkout',
        method: 'POST',
        payload: JSON.stringify(checkoutData),
      };

      const checkoutResponse = await api(checkoutRequest);

      return checkoutResponse;
    } catch (e) {
      console.error('api error', e.message);
    }
  };

  const redirectToStripe = (responseUrl) => {
    pageView(TRACKING_STRIPE_PAYMENT_PAGE);
    updateTrackingLayer('Go To Checkout');

    // give some time to run the tracking functions before changing page
    setTimeout(() => {
      window.location.replace(responseUrl);
    }, 1000);
  };

  const handlePlanSelect = async (price) => {
    if (!price) return;

    const { id: priceId, recurring } = price;

    // if price has a trial period, include it in the payload
    const freeTrialPayload = recurring?.trial_period_days
      ? {
          subscription_data: {
            trial_period_days: recurring.trial_period_days,
            trial_settings: {
              end_behavior: {
                missing_payment_method: 'cancel',
              },
            },
          },
        }
      : {};

    const checkoutData = {
      mode: 'subscription',
      customer_email: userEmail,
      client_reference_id: userId,
      allow_promotion_codes: true,
      line_items: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      // this param tells stripe not to collect payment if not required
      // payment_method_collection: 'if_required',
      success_url: `${REACT_APP_WEB_BASE_URL}/thank-you?userId=${encodeURIComponent(
        userId
      )}`,
      cancel_url: `${REACT_APP_WEB_BASE_URL}/plans?userId=${encodeURIComponent(
        userId
      )}&userEmail=${encodeURIComponent(userEmail)}`,
      ...freeTrialPayload,
    };

    const response = await handleStripeCheckout(checkoutData);
    const { url: responseUrl } = response;

    if (responseUrl) {
      redirectToStripe(responseUrl);
    } else {
      console.error('Missing response/url from checkout', response);
    }
  };

  if (noUser && !loading) {
    if (!isFromStripe || !areStripeUserInfoValid(userId, userEmail)) {
      redirectHome();
    }
  }

  return (
    <>
      <HeaderNav
        page={1}
        changePage={() =>
          state?.skippedEligibilityCheck || state?.affiliate?.covers_ayble
            ? navigate(`/eligibility${search}`, {
                state: {
                  affiliate: state?.affiliate,
                  userId,
                  userEmail,
                },
              })
            : navigate(-1, {
                state: {
                  affiliate: state?.affiliate,
                  userId,
                  userEmail,
                },
              })
        }
      />
      <Center w={'100%'}>
        <Container w={'100%'} maxW={'100%'} mt={10} px={4}>
          <VStack>
            <ProductSelector
              affiliate={state?.affiliate}
              skippedEligibilityCheck={state?.skippedEligibilityCheck}
              lookedUpAffiliate={state?.lookedUpAffiliate}
              onConsultationClick={handleConsultationClick}
              onPlanSelect={handlePlanSelect}
            />
            <PlansDisclaimer />
            <Divider mt={4} mb={2} />
            <PlansValueProp />
          </VStack>
        </Container>
      </Center>
    </>
  );
}

export default Plans;
